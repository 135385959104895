import React, { useState } from "react";
import { Link } from "gatsby";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
import moment from "moment";
import convertSlug from "@/utils/convertSlug";
import usePosts from "@/hooks/usePosts";

function Search(Search: any) {
  Search = "";
  const data = usePosts();

  const [searchTerm, setTerm] = useState(Search);
  function text(paragraph: any) {
    return paragraph
      .map((block: any) => {
        return block.text;
      })
      .join("");
  }

  const FilteredPosts = data.filter((m: any) => {
    return (
      m?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      text(m.contentFull.document[0].children)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });

  const articleBuilder = FilteredPosts.map((article: any) => {
    return (
      <div
        key={FilteredPosts.indexOf(article)}
        className="bg-theme text-white p-[1rem] overflow-hidden shadow-lg mb-[1rem]"
      >
        <div className="flex gap-[2rem]">
          {/* Image */}
          <div className="relative h-max">
            <a href="relative group" aria-label={article.title}>
              <div className="group-hover:opacity-1 opacity-0">
                <span className="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-full bg-black opacity-80"></span>
                <AiOutlinePlus
                  color="white"
                  className="absolute left-[50%] top-[50%] z-20 -translate-x-[50%] -translate-y-[50%] opacity-100"
                />
              </div>
              <div className="">
                {article.featureImage && (
                  <KeystoneImage
                    baseUrl="smp"
                    image={{
                      alt: article.featureImage.id,
                      width: 72,
                      height: 72,
                      key: `${article.featureImage.id}.${article.featureImage.extension}`,
                    }}
                    layout="fixed"
                    alt={article.featureImage.id}
                  />
                )}
              </div>
            </a>
          </div>

          {/* Title */}
          <div className="">
            <Link
              to={`/news/${convertSlug(article.title)}`}
              className="font-[700] duration-300 ease-in-out hover:text-black"
            >
              <p className="font-[700] leading-[30px]">{article.title}</p>
            </Link>
          </div>
        </div>

        {/* Date */}
        <p className="mt-[0.5rem] text-[12px] font-[400]">
          {moment(article.publishDate).format("LL")}
        </p>
      </div>
    );
  });

  return (
    <div className="xl:container sm:px-[5rem] lg:px-0">
      <div
        className={`relative mb-[2rem] flex border-[1px] mx-auto border-lightGray bg-white  lg:flex lg:border-lightGray focus:border-black`}
      >
        <input
          className="outline-none py-[14px] pl-[24px] w-[100%]"
          onChange={(event) => {
            setTerm(event.target.value);
          }}
          placeholder="Search..."
          type="text"
        ></input>

        <button
          name="Search"
          aria-label="Search"
          className="absolute right-2 top-[50%] -translate-y-[50%]"
        >
          <AiOutlineSearch
            size={24}
            className="hover:fill-black"
            color="gray"
          />
        </button>
      </div>
      {articleBuilder}
    </div>
  );
}

export default Search;
