import React from "react";
import Layout from "@/components/Layout";
import { graphql } from "gatsby";
import RecentPosts from "@/components/RecentPosts";

import {
  FaFacebookF,
  FaTwitter,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import moment from "moment";
import { KeystoneImage } from "@/components/gatsby-image-keystone";

import usePosts from "@/hooks/usePosts";
import { CustomRenderer } from "@/utils/CustomRenderer";

const PostPage = ({ data, ...props }: any) => {
  const posts = usePosts();
  const { post } = data?.cms;
  const { title, id, content, contentFull, featureImage, publishDate } = post;
  const { settings } = props.pageContext;
  const socialStyles = `md:ml-[2rem] mr-[1rem] md:mr-0 duration-300 ease-in-out hover:fill-theme`;

  const index = posts.findIndex((object: any) => {
    return object.id === id;
  });

  return (
    <Layout title={title} settings={settings}>
      <div className="xl:container px-[20px] xl:px-0">
        <div className="mt-[2rem] lg:grid lg:grid-cols-[64%_36%]">
          {/* Article */}
          <div className="">
            <div>
              {featureImage && (
                <KeystoneImage
                  baseUrl="smp"
                  image={{
                    alt: featureImage.id,
                    width: 815,
                    height: featureImage.height * (815 / featureImage.width),
                    key: `${featureImage.id}.${featureImage.extension}`,
                  }}
                  layout="fullWidth"
                  alt={featureImage.id}
                />
              )}
            </div>

            {/* Post Details */}
            <div className="md:flex justify-between">
              {/* Date */}
              <div className="relative my-[1rem]">
                <span className="absolute top-[0%] h-[24px] -translate-y-[0%] border-l-[4px] border-black"></span>
                <div className="pl-[1rem] text-left text-[14px] font-[700] uppercase">
                  POSTED ON {moment(publishDate).format("LL")} IN NEWS
                </div>
              </div>

              {/* Socials */}
              <div className="flex my-auto">
                <a href="">
                  <FaFacebookF size={24} className={socialStyles} />
                </a>
                <a href="">
                  <FaTwitter size={24} className={socialStyles} />
                </a>
                <a href="">
                  <FaPinterest size={24} className={socialStyles} />
                </a>
                <a href="">
                  <FaLinkedin size={24} className={socialStyles} />
                </a>
              </div>
            </div>

            {/* Title */}

            {/* Article */}
            <article className="prose lg:mt-[3rem]">
              <h1 className="my-[1rem] lg:text-[35px]">{title}</h1>
              <div>
                <CustomRenderer document={contentFull.document} />
              </div>
            </article>

            {/* Next & Previous post */}
            {/* <div className="flex justify-between mt-[1rem] mb-[2rem]">
              <div className="mt-[1rem] border-t-[1px] border-lightGray text-right ">
                {count !== 0 && (
                  <Link
                    onClick={() => {
                      setCount(count - 1);
                    }}
                    aria-label={post.title}
                    to={`/news/${convertSlug(posts[count - 1].title)}`}
                    className={`font-[500] bg-black  py-[10px] pr-[1.5rem] pl-[0.5rem] hover:bg-theme text-white md:py-[0.5rem] flex justify-between duration-300 ease-in-out text-[12px] md:text-[16px]`}
                  >
                    <IoIosArrowBack className="my-auto mr-[1rem]" />
                    Previous post
                  </Link>
                )}
              </div>
              <div className="mt-[1rem] border-t-[1px] border-lightGray text-right ">
                {count !== posts.length - 1 && (
                  <Link
                    onClick={() => {
                      setCount(count + 1);
                    }}
                    to={`/news/${convertSlug(posts[count + 1].title)}`}
                    className="font-[500] bg-black py-[10px] pl-[1rem] md:pl-[1.5rem] md:pr-[0.5rem] hover:bg-theme text-white md:py-[0.5rem] flex justify-between duration-300 ease-in-out text-[12px] md:text-[16px]"
                  >
                    Next post
                    <IoIosArrowForward className="my-auto ml-[1rem]" />
                  </Link>
                )}
              </div>
            </div> */}
          </div>

          {/* Recents */}
          <div className="lg:pl-[6rem] sm:px-[2rem]">
            <div className="relative">
              <p className="text-[18px] font-[700]">Recent posts</p>
            </div>

            {/* Recent Articles */}
            <RecentPosts />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NewsById($id: ID) {
    cms {
      post(where: { id: $id }) {
        title
        id
        publishDate
        localization {
          code
        }
        content {
          document(hydrateRelationships: true)
        }
        contentFull {
          document(hydrateRelationships: true)
        }
        featureImage {
          extension
          filesize
          height
          id
          width
        }
      }
    }
  }
`;

export default PostPage;
